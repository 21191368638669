body {
  background-color: black;
  color: #ddd;
}

.content {
  text-align: center;
  a:hover {
    color: red;
  }
  a:link {
    color: #eee;
  }
  a:visited {
    color: #eee;
  }
}

.content img {
  width: 400px;
}